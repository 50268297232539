import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import auth from './modules/auth'
import tagsView from './modules/tagsView'
import permission from "./modules/permission";
import manage from './modules/manage'
import design from "./modules/design";
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		device: "pc",
		globalLoading: false,
		defaultNewSign: -1,
		defaultReplySign: -1,
		searchEmail: "",
		gmail: "CustomerServiceTeam@gs-jj.com",
		// gmail: "hzj072214@gmail.com",
		emailPage: 1,
		emailPageSize: 50,
		emailDetailBack: false
	},
	mutations: {
		setEmailDetailBack(state,data) {
			state.emailDetailBack = data;
		},
		setDefaultNewSign(state, data) {
			state.defaultNewSign = data;
		},
		setDefaultReplySign(state, data) {
			state.defaultReplySign = data;
		},
		setDevice(state, data) {
			state.device = data;
		},
		setGlobalLoading(state, data) {
			state.globalLoading = data;
		},
		setSearchEmail(state, data) {
			state.searchEmail = data;
		},
		setEmailPage(state, data) {
			state.emailPage = data;
		},
		setEmailPageSize(state, data) {
			state.emailPageSize = data;
		}
	},
	actions: {},
	modules: {
		app,
		auth,
		tagsView,
		permission,
		manage,
		design
	},
	getters
});
