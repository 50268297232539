import request from "@/utils/request";

export function getFreebieListByProld(data) {
	return request({
		url: "/freebie/getFreebieListByProId",
		method: "post",
		data: data,
		hideLoading: true,
	});
}

export function getAllQuoteCate(data) {
	return request({
		url: "/quote/cate/getAllQuoteCateAndHalfProductCate",
		method: "post",
		data: data,
	});
}

export function getWebAllQuoteCate(id) {
	return request({
		url: "/quote/cate/getWebsiteAllQuoteCate?proId=" + id,
		method: "get",
	});
}

//获取项目名
export function getSystemProject(data) {
	return request({
		url: "/systemProject/getByProId",
		method: "post",
		data: data,
	});
}

// 订单图稿上传
export function uploadOrderDrawings(data) {
	return request({
		url: "/order/manage/uploadTheDrawings",
		method: "post",
		data: data,
	});
}

// 图稿更新
export function updateProductionOrderArtwork(data) {
	return request({
		url: "/invoice/updateProductionOrderArtwork",
		method: "post",
		data: data,
	});
}

// 订单图稿暂存
export function storeOrderDrawings(data) {
	return request({
		url: "/order/manage/drawingsAreTemporarilyStored",
		method: "post",
		data: data,
	});
}

//添加订单
export function addOrder(data) {
	return request({
		url: "/order/manage/addOrder",
		method: "post",
		data: data,
	});
}

// 获取询盘图稿信息
export function getInquiryDraftInfo(data) {
	return request({
		url: "/customLinks/getLinkProductByInquiryId",
		method: "post",
		data: data,
	});
}

//获取订单图稿信息
export function getOrderDraftInfo(data) {
	return request({
		url: "/order/manage/getOrderDrawing",
		method: "post",
		data: data,
	});
}

//获取订单图稿信息
export function editOrderCurrency(data) {
	return request({
		url: "/order/manage/editOrderCurrency",
		method: "post",
		data: data,
	});
}

//询盘图稿上传
export function uploadInquiryDrawings(data) {
	return request({
		url: "/customLinks/edit",
		method: "post",
		data: data,
	});
}

// 图稿删除
export function deleteOrderProduct(data) {
	return request({
		url: "/order/manage/deleteOrderProduct",
		method: "post",
		data: data,
	});
}
// 订单0和1的切换：：：0
export function unLockOrderProduct(data) {
	return request({
		url: "/order/manage/unLockOrderProduct",
		method: "post",
		data,
	});
}
// 订单0和1的切换：：：1
export function lockOrderProduct(data) {
	return request({
		url: "/order/manage/lockOrderProduct",
		method: "post",
		data,
	});
}

// 询盘0和1的切换：：：0
export function unLockInquiryOrderProduct(data) {
	return request({
		url: "/inquiry/unLockInquiryOrderProduct",
		method: "post",
		data,
	});
}
// 询盘0和1的切换：：：0
export function lockInquiryOrderProduct(data) {
	return request({
		url: "/inquiry/lockInquiryOrderProduct",
		method: "post",
		data,
	});
}

//获取用户免税信息
export function getByUserEmail(data) {
	return request({
		url: "/extended/getByUserEmail",
		method: "post",
		data,
		hideLoading: true,
	});
}

//提交用户免税信息
export function editTaxExemptionByUserEmail(data) {
	return request({
		url: "/extended/editTaxExemptionByUserEmail",
		method: "post",
		data,
		notRemoveEmptyParams: true,
	});
}

//获取用户免税信息
export function getCouponByCode(data) {
	return request({
		url: "/coupon/getCouponByCode",
		method: "post",
		data,
	});
}

//
export function getDefaultLanguage(proId) {
	return request({
		url: "/language/getDefaultLanguageByProId?proId=" + proId,
		method: "get",
	});
}

//
export function getCurrency(code) {
	return request({
		url: "/quote/currency/getCurrencyByCode?code=" + code,
		method: "get",
	});
}
//
export function translateDesc(data) {
	return request({
		url: "/order/manage/translateOrderProductDesc",
		method: "post",
		data,
		hideLoading: true,
	});
}

//
export function getCateParamByCateId(id) {
	return request({
		url: "/quote/cate/getCateParamRelationByCateId?cateId=" + id + "&isBack=1",
		method: "get",
	});
}

//获取选中的报价参数
export function getNeonQuoteToolParam(data) {
	return request({
		url: "/quote/cate/getNeonQuoteToolParam",
		method: "post",
		data,
		hideLoading: true,
	});
}

//
export function ocrArtworkParam(data) {
	return request({
		url: "/image/ocrArtworkParam",
		method: "post",
		data,
	});
}

//报价工具计算
export function neonQuoteToolCalculate(data) {
	return request({
		url: "/quote/quoteCalculate/neonQuoteToolCalculate",
		method: "post",
		data,
		hideLoading: true,
	});
}

//块计算
export function calculatePieceNumber(data) {
	return request({
		url: "/quote/quoteCalculate/calculatePieceNumber",
		method: "get",
		data,
		hideLoading: true,
	});
}

//报价工具计算
export function updateLowStatus(data) {
	return request({
		url: "/order/manage/used/update",
		method: "post",
		data,
	});
}

//报价工具计算
export function getProdList(data) {
	return request({
		url: "/systemProject/list",
		method: "post",
		data,
	});
}
//获取历史版本的确认图稿
export function getHistoryArtwork(data) {
	return request({
		url: "/newTask/commitRecords/getHistoryConfirmCommitArtwork",
		method: "post",
		data,
		notRemoveEmptyParams: true,
		hideLoading: true,
	});
}

export function getProductByCateIdAndSku(data) {
	return request({
		url: "/products/getProductByCateIdAndSku",
		method: "post",
		data,
	});
}

export function getAddressByUserEmailAndProId(data) {
	return request({
		url: "/member/userAddress/getAddressByUserEmailAndProId",
		method: "post",
		data,
		hideLoading: true,
	});
}

export function editFdUserAddress(data) {
	return request({
		url: "/member/userAddress/editUserAddress",
		method: "post",
		data,
		hideLoading: true,
	});
}

export function getOrderAddress(data) {
	return request({
		url: "/order/manage/getOrderAddressByOidAndProId",
		method: "post",
		data,
	});
}

export function getOrderInfoByOidAndProId(data) {
	return request({
		url: "/order/manage/getOrderInfoByOidAndProId",
		method: "post",
		data,
	});
}

export function getUserBaseInfoForInvoice(data) {
	return request({
		url: "/member/getUserBaseInfoForInvoice",
		method: "post",
		data,
	});
}

export function getRetailerAddressByEmailAndProId(data) {
	return request({
		url: "/member/getRetailerAddressByEmailAndProId",
		method: "post",
		data,
	});
}

export function addRetailerAddress(data) {
	return request({
		url: "/member/addRetailerAddress",
		method: "post",
		data,
	});
}
export function getPopCommonUser(data) {
	return request({
		url: "/member/getPopCommonUser",
		method: "post",
		data,
		hideLoading: true,
	});
}

export function getPopInvoiceExtraData() {
	return request({
		url: "/invoice/getPopInvoiceExtraData",
		method: "get",
	});
}
export function getUserAddressByEmail(data) {
	return request({
		url: "/member/getUserAddressByEmail",
		method: "post",
		data,
		hideLoading: true,
	});
}

//获取用户免税信息（促销）
export function detailCode(data) {
	return request({
		url: "/promotion/send/code/detail",
		method: "post",
		data,
	});
}
