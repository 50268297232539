import { setup } from "@/lang/index";
import { logined } from "@/api/auth";
import { setToken } from "@/utils/auth";
import { getAllSalesMan } from "@/api/o2oApi/dmsManage/inquiry";
import { getAllQuote, getMarks, getQuoteCateList, getListAsTree } from "@/api/o2oApi/dmsManage/order";
import { getCountryList } from "@/api/set";
import { getProjectListByProType } from "@/api/price";
import { getWebAllQuoteCate } from "@/api/o2oApi/dmsManage/uploadDraft";

const state = {
	cateTreeList: [],
	labelList: [],
	projectQuoteCate: [],
	allProjectList: {},
	countryList: [],
	salesmanList: [],
	allQuoteList: [],
	allFdQuoteList: [],
	imgProgress: {
		showProgress: true,
		uploadList: [],
	},
	language: setup(),
	showUserDetail: false,
	professionList: [
		{
			id: 1,
			icon: "#333",
			txt: "designer, freelance, student etc",
		},
		{
			id: 2,
			icon: "#0064c8",
			txt: "organization director, retail store owner",
		},
		{
			id: 3,
			icon: "#ff6a00",
			txt: "purchaser, events planner",
		},
		{
			id: 4,
			icon: "rgb(240, 185, 11)",
			txt: "advertising agent, marketing specialist",
		},
		{
			id: 5,
			icon: "#3eaf7c",
			txt: "government agent, militarist, educator",
		},
		{
			id: 6,
			icon: "red",
			txt: "other",
		},
	],
	typeAIntentionList: [
		{
			id: 2,
			name: "意向不明确",
		},
		{
			id: 3,
			name: "有一点点意向",
		},
		{
			id: 4,
			name: "有明确意向",
		},
		{
			id: 5,
			name: "确定准备推荐",
		},
		{
			id: 6,
			name: "拒绝但态度不强硬",
		},
		{
			id: 7,
			name: "拒绝无合作可能",
		},
	],
	typeCIntentionList: [
		{
			id: 1,
			name: "已推荐",
		},
		{
			id: 2,
			name: "意向不明确",
		},
		{
			id: 3,
			name: "有一点点意向",
		},
		{
			id: 4,
			name: "有明确意向",
		},
		{
			id: 5,
			name: "确定准备推荐",
		},
		{
			id: 6,
			name: "拒绝但态度不强硬",
		},
		{
			id: 7,
			name: "拒绝无合作可能",
		},
	],
	pageData: {},
};

const mutations = {
	SET_PAGEDATA(state, data) {
		state.pageData[data.name] = data.data;
	},
	SET_LANGUAGE: (state, language) => {
		state.language = language;
	},
	set_showUserDetail(state, bool) {
		state.showUserDetail = bool;
	},
	changeProgressStatus(state, data) {
		state.imgProgress.showProgress = data;
	},
	addUploadItem(state, data) {
		state.imgProgress.uploadList.push(data);
	},
	setCateList(state, data) {
		state.cateTreeList = data;
	},
	setLabelList(state, data) {
		state.labelList = data;
	},
	setCountryList(state, data) {
		state.countryList = data;
	},
	setSalesList(state, data) {
		state.salesmanList = data;
	},
	setQuoteList(state, data) {
		state.allQuoteList = data;
	},
	// setFdQuoteList(state,data){
	// 	state.allFdQuoteList = data;
	// },
	setProjectList(state, data) {
		state.allProjectList = data;
	},
	setProjectQuoteCateList(state, data) {
		state.projectQuoteCate = data;
	},
};
const actions = {
	setLanguage({ commit }, language) {
		commit("SET_LANGUAGE", language);
	},
	getCateList({ commit }) {
		return new Promise((resolve, reject) => {
			getListAsTree()
				.then((res) => {
					const { data } = res;
					commit("setCateList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMarks({ commit }) {
		return new Promise((resolve, reject) => {
			getMarks()
				.then((res) => {
					const { data } = res;
					commit("setLabelList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getCountry({ commit }) {
		return new Promise((resolve, reject) => {
			getCountryList()
				.then((res) => {
					const { data } = res;
					commit("setCountryList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getAllSales({ commit }) {
		return new Promise((resolve, reject) => {
			getAllSalesMan({ deptId: 3 })
				.then((res) => {
					const { data } = res;
					commit("setSalesList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getQuoteList({ commit }) {
		return new Promise((resolve, reject) => {
			getAllQuote()
				.then((res) => {
					const { data } = res;
					commit("setQuoteList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getProjectList({ commit }) {
		return new Promise((resolve, reject) => {
			getProjectListByProType({ page: 1, pageSize: 9999 })
				.then((res) => {
					commit("setProjectList", res.data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	/*	getFdQuoteList({commit}) {
		return new Promise((resolve, reject) => {
			getWebAllQuoteCate(9).then(res => {
				const {data} = res
				commit('setFdQuoteList', data)
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},*/
	getProjectQuoteCateList({ commit }) {
		return new Promise((resolve, reject) => {
			getQuoteCateList("", "")
				.then((res) => {
					const { data } = res;
					commit("setProjectQuoteCateList", data);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
