import layout from "@/views/layout/index.vue";
const drawingTaskList = () => import("@/views/drawingSystem/drawingTaskList.vue");
const drawingTaskDetail = () => import("@/views/drawingSystem/drawingTaskDetail/index.vue");
const drawingTaskStatistics = () => import("@/views/drawingSystem/drawingTaskStatistics.vue");
const resourceLibrary = () => import("@/views/drawingSystem/resourceLibrary.vue");
const mySignage = () => import("@/views/drawingSystem/mySignage/index.vue");
const tuGaoAllocationConfiguration = ()=> import("@/views/drawingSystem/allocationConfiguration.vue");
const drawingTemplateManagement = () => import("@/views/drawingSystem/drawingTemplateManagement.vue");
const taskCategoryManagement = () => import("@/views/drawingSystem/taskCategoryManagement.vue");
export default {
	path: "/drawingSystem",
	name: "drawingSystem",
	component: layout,
	meta: {
		title: "图稿系统",
		icon: "el-icon-picture"
	},
	children: [
		{
			path: "tuGaoAllocationConfiguration",
			name: "tuGaoAllocationConfiguration",
			component: tuGaoAllocationConfiguration,
			meta: {
				title: "分配配置"
			}
		},
		{
			path: "mySignage",
			name: "mySignage",
			component: mySignage,
			meta: {
				title: "我的看板"
			}
		},
		{
			path: "drawingTaskList",
			name: "drawingTaskList",
			component: drawingTaskList,
			meta: {
				title: "图稿任务列表"
			}
		},
		{
			path: "drawingTaskDetail",
			name: "drawingTaskDetail",
			component: drawingTaskDetail,
			hidden: true,
			meta: {
				title: "图稿任务详情"
			}
		},
		{
			path: "drawingTaskStatistics",
			name: "drawingTaskStatistics",
			component: drawingTaskStatistics,
			meta: {
				title: "图稿任务统计"
			}
		},
		{
			path: "resourceLibrary",
			name: "resourceLibrary",
			component: resourceLibrary,
			meta: {
				title: "资源库"
			}
		},
		{
			path: "drawingTemplateManagement",
			name: "drawingTemplateManagement",
			component: drawingTemplateManagement,
			meta: {
				title: "模版管理"
			}
		},
    {
			path: "taskCategoryManagement",
			name: "taskCategoryManagement",
			component: taskCategoryManagement,
			meta: {
				title: "任务类别管理"
			}
		}
	]
};
