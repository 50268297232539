<template>
	<div class="box">
		<div class="topNavBar">
			<div class="left" v-if="!MenuFlag">
				<breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>
			</div>
			<div class="hView">
				<div class="openStatus" style="position: relative" :style="'width:'+topWidth+'%'" v-show="!MenuFlag">
					<div @click="openStatus = true">
						<el-cascader :key="modalKey" style="pointer-events: none;" placeholder="请选择平台" ref="cascader"
									 v-model="ptSelect" :options="originList"
									 :value="ptSelect"
									 :class="{hasChild:ptSelect.length>1}"
									 :show-all-levels="false"
									 :props="{ multiple: true, checkStrictly: true }" filterable
									 clearable></el-cascader>
					</div>
					<span v-if="ptSelect.length>0" class="title_span el-input__suffix-inner" @click="clearPtList"><i
						class="el-input__icon el-icon-circle-close el-input__clear"></i></span>
				</div>

				<el-dropdown @command="handleProCommand" hidden v-show="!MenuFlag">
					<span class="el-dropdown-link">
						{{ $t("leftSidebar.xuanzexiangmu") }}({{ proName }})<i
						class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown" style="max-height: 500px;overflow: hidden auto;">
						<el-dropdown-item v-for="item in projectList" :key="item.proName" :label="item.proName"
										  :command="item">
							{{ item.proName }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown @command="handleCommand" style="margin-left: 30px;min-width: 100px;" v-show="!MenuFlag">
                <span class="el-dropdown-link">
                    {{ $t("leftSidebar.xuanzeyuyan") }}({{ lang }})<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
					<el-dropdown-menu slot="dropdown" >
						<el-dropdown-item command="zh">简体中文</el-dropdown-item>
						<el-dropdown-item command="en">English</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div style="display: flex;align-items: center;margin-left: 30px;min-width: 110px;">
					<el-avatar icon="el-icon-user-solid" style="margin-right: 5px;"></el-avatar>
					<el-dropdown @command="handleCommand2">
                    <span class="el-dropdown-link pointer text-white">
                        {{ MenuFlag? NavHeader :$store.getters.userName }} <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item command="switchAccount" v-show="!MenuFlag">
									{{ $t("leftSidebar.switchAccount") }}
								</el-dropdown-item>
								<!--<el-dropdown-item command="changePassword">-->
								<!--	{{ $t("leftSidebar.xiugaimima") }}-->
								<!--</el-dropdown-item>-->
								<el-dropdown-item command="logOut">{{ $t("leftSidebar.tuichu") }}</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</div>
		</div>
		<CustomSelectPop v-if="openStatus" :openStatus.sync="openStatus" :options="options" :originList="originList" :clearPt="clearPt" @submitList="submitList" @changeClearPt="handleChangeClearPt"></CustomSelectPop>
	</div>

</template>

<script>
import breadcrumb from "@/components/Breadcrumb";
import Cookies from "js-cookie";
import CustomSelectPop from "@/components/CustomSelectPop";
import { getLiyiUserName} from "@/utils/auth";
export default {
	name: "Navbar",
	data() {
		return {
			modalKey: 6,
			index: 3,
			proType: "",
			originList: [{
				value: 0,
				label: this.$t("inquiryPage.dealeSelfOperatedPlatformrs"),
				children: [{}]
			}, {
				value: 1,
				label: this.$t("inquiryPage.dealer"),
				children: [{}]
			}, {
				value: 2,
				label: this.$t("inquiryPage.allianceBusiness"),
				children: [{}]
			}],
			options: [
				{
					value: 0,
					label: "自营平台",
					children: [{}],
					proType:0
				}, {
					value: 1,
					label: "FD",
					proType:0
				},  {
					value: 2,
					label: "POP",
					proType:0
				}, {
					value: 3,
					label: "其他",
					children: [{
						value: 1,
						label: "O2O经销商",
						children: [{}],
						proType:1
					},{
						value: 2,
						label: "USPP加盟商",
						children: [{}],
						proType:2
					}]
				}],
			ptSelect: JSON.parse(sessionStorage.getItem("ptSelect")) || [],
			selectAll: false,
			showTypeChildList: false,
			selectedIndex: -1,
			selectedChildIndex: [],
			searchNum: "",
			openStatus: false,
			topWidth: JSON.parse(sessionStorage.getItem("ptSelect"))?.length*6,
			clearPt: false,
		};
	},
	watch: {
		ptSelect(val){
			if(val.length*9>100) return
			this.topWidth = val.length*6;
			if(this.topWidth == 0){
				this.topWidth = 13;
			}
		}
	},
	computed: {
		lang() {
			return this.$store.getters.language;
		},
		proName() {
			return this.$store.state.manage?.currentSite?.proName;
		},
		projectList() {
			return this.$store.state.manage.projectList;
		},
		navBar() {
			return this.$store.state.manage.navBar;
		},
		stopNavHover() {
			return this.$store.state.manage.stopBarHover;
		},
		allProjectList () {
			return this.$store.state.app.allProjectList;
		},
		loginSource() {
			return this.$store.state.auth.loginSource || 'ly';
		},
		MenuFlag(){
            return this.$store.state.permission.MenuFlag
		},
		NavHeader(){
			return getLiyiUserName() || ""
		}
	},
	components: {
		CustomSelectPop,
		breadcrumb
	},
	methods: {
		clearPtList() {
			this.clearPt = true;
			this.ptSelect = [];
			sessionStorage.removeItem("ptSelect");
			this.$store.commit("manage/updatePtSelected", []);
		},
		submitList(val){
			let data = JSON.parse(JSON.stringify(val));
			this.$store.commit("manage/updatePtSelected", data);
			sessionStorage.setItem("ptSelect", JSON.stringify(data));
			this.ptSelect = val;
			this.$forceUpdate();
			this.openStatus =false;
		},
		handleChangeClearPt(e){
			this.clearPt = e;
		},
		changeNav() {
			this.$store.commit("manage/changeNavBar", !this.navBar);
			this.$store.commit("manage/stopBarHover", !this.stopNavHover);

			if (localStorage.getItem("lefNavBar") === "true") {
				localStorage.setItem("lefNavBar", "false");
			} else {
				localStorage.setItem("lefNavBar", "true");
			}

			if (localStorage.getItem("stopBarHover") === "true") {
				localStorage.setItem("stopBarHover", "false");
			} else {
				localStorage.setItem("stopBarHover", "true");
			}
		},
		handleCommand(lang) {
			this.$setup(lang);
			this.$store.dispatch("app/setLanguage", lang);
			this.$router.go(0);// fix语言切换后，element-ui弹窗提示文字不能切换，必须强制刷新页面才能解决的bug
		},
		handleProCommand(item) {
			if (item.id === this.$store.state.manage.currentSite.id) {
				return false;
			}
			Cookies.set("manageProId", item.id, {expires: 30});
			this.$store.commit("manage/updateSite", item);
			this.$router.go(0);
		},
		handleCommand2(command) {
			if (command == "logOut") {
				if(this.MenuFlag){
					Cookies.remove('liyiadmin')
                    location.replace(`/liyiRegister`)
				}else{
					this.$store.dispatch("auth/logout").then(() => {
						location.replace(`/${this.loginSource}/login`)
						// if (this.$store.getters.typeId == "ww7caead352e59537b" || this.$store.getters.typeId == 'ww8d4dbca0a27dc4b4') {
						//     location.href="/ly/login"
						// } else if (this.$store.getters.typeId == "wwcd713a97fdc2c8c6") {
						//     location.href="/qm/login"
						// } else if (this.$store.getters.typeId == "ww3eb91dcf504dad24") {
						//     location.href="/cs/login"
						// }else {
						//     location.href="/google/login"
						// }
					});
				}
				sessionStorage.clear();
				localStorage.clear();
			} else if (command === 'switchAccount') {
				// 切换账号
				this.$router.push({name: 'login', params: {id: this.loginSource}, query: {is_band: '2', is_login: '1'}});
			} else {//修改密码
				this.$router.push({path: "/change/changePassWord"});
			}
		},

	},
	created() {
		// console.log(this.$store.state.permission.MenuFlag,'>>>')
		if(this.MenuFlag) return
		// this.allProjectList[0] 自营平台
		// this.allProjectList[1] O2O经销商
		// this.allProjectList[2] USPP加盟商
		// 现将目录改为 this.options[0] 自营平台、 this.options[1] FD、 this.options[2] POP、 this.options[3] 其他,  自营平台中的FD、POP 不显示在页面上
		for (let i in this.originList) {
			this.originList[i].children = this.allProjectList[i].map(item => {
				let obj = {};
				obj.url = item.url;
				obj.proType = item.proType;
				obj.label = item.proName;
				obj.value = String(item.id);
				obj.proLogo = item.proLogo;
				obj.proLogoBack = item.proLogoBack;
				return obj;
			});
		}
		let data = []
		for (let i in this.allProjectList) {
			if (!this.allProjectList[i].length) {
                    return;
                }
			let arr = []
			arr = this.allProjectList[i].map(item => {
				let obj = {};
				obj.url = item.url;
				obj.proType = item.proType;
				obj.label = item.proName;
				obj.value = String(item.id);
				obj.proLogo = item.proLogo;
				obj.proLogoBack = item.proLogoBack;
				return obj;
			});
			data.push(arr)
		}
		this.options[0].children = data[0].filter(child=>child.value !== '9' && child.value !== '10');
		let obj = {
			value: 1,
			label: "O2O经销商",
			children: data[1]
		}
		let obj1 = {
			value: 2,
			label: "USPP加盟商",
			children: data[2]
		}
		this.options[3].children = [obj,obj1]
	}
};
</script>
<style lang="scss" scoped>
::v-deep .el-cascader{
	width: 100%;
	.el-cascader__tags{
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
		max-width: 100%;
		>span i{
			display: none;
		}
	}

	&.hasChild{

		.el-cascader__tags:nth-child(1) {
			display: none;
		}
	}
}

.box{
	.topNavBar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #242f42;
		height: 50px;
		color: #fff;

		.left {
			min-width: 45px;
			margin-right: 10px;

			.hamburger-container {
				//padding: 0 20px;
				padding-right: 10px;
				height: 100%;
				line-height: 50px;
				cursor: pointer;
				transition: background .3s;


				&:hover {
					background: rgba(255, 255, 255, .025);
				}

				i {
					color: #fff;
					font-size: 26px;
					vertical-align: middle;

					&.isClick {
						color: rgb(255, 208, 75);
					}
				}
			}
		}


		.hView {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-align: right;
			margin-right: 20px;

			.openStatus {
				position: relative;
				max-width: 100%;
				.title_span {
					position: absolute;
					color: #999;
					cursor: pointer;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				::v-deep .el-input__suffix {
					display: none;
				}

				::v-deep .el-cascader__search-input {
					display: none;
				}

				::v-deep .el-cascader__tags {
					span:nth-child(1) {
						//white-space: nowrap;
						//overflow: hidden;
						//text-overflow: ellipsis;
						//width: 7vw;
					}

					span:nth-child(2) {
						//white-space: nowrap;
						//overflow: hidden;
						//text-overflow: ellipsis;
						//width: 2vw;
					}
				}
			}

			.el-dropdown {
				color: #fff;
			}
		}
	}

	.select-pop {
		::v-deep{
			.el-dialog {
				border-radius: 10px;
				background-image: url("https://oss-static-cn.liyi.co/web/quoteManage/20240312/beijingtu3_20240312jyXnDe.png");
				background-size: 100% 100%;
			}
			.el-dialog__header{
				padding: 20px 10px 0 10px;
				text-align: center;
				.el-dialog__title{
					color: #fff;
				}
			}

			.el-dialog__body {
				padding: 0 10px 40px 10px;
				box-shadow: none;
				border-radius: 10px;
			}

			.el-checkbox__inner{
				background: transparent;
			}
			.el-checkbox__label,.el-dialog__close{
				color: white;
			}

		}
		.el-icon-back {
			font-size: 22px;
			font-weight: 700;
		}

		.cursor {
			cursor: pointer;
			color: #0256ff;
			//margin-bottom: 1vw;
		}
		.productType{
			display: flex;
			color: rgb(248,148,18);
			justify-content: flex-start;
		}

		.childType{
			.select{
				display: flex;
				justify-content: space-between;
				padding-right: 0.8em;
			}
		}
		.productTypeList {
			font-size: 18px;
			color: rgb(248,148,18);
			grid-template-columns: repeat(3,1fr) !important;
			justify-content: center !important;
		}
		.cancel{
			background-color: darkgray;
			::v-deep.v-btn__content{
				color: rgb(118,130,150);
			}
		}
		.productTypeList,
		.childTypeList {
			display: grid;
			grid-template-columns: repeat(6,1fr);
			max-height: 52vh;
			overflow: auto;

			&::-webkit-scrollbar {
				width: 4px;
				height: 3px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: rgba($color: #ccc, $alpha: 0.2);
			}

			.typeLabel,
			.childTypeLabel {
				cursor: pointer;
				width: 10vw;
				height: 5.4vw;
				margin: 2em;
				padding: 0 8px;
				border-radius: 5px;
				color: #3d3d3d;
				background: #ffffff;
				box-sizing: border-box;
				border: 1px solid #000000;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				&.active {
					//background: rgba(192, 214, 245, 0.44);
					border: 2px solid rgb(248, 148, 18);
				}

				.icon {
					position: absolute;
					top: -15%;
					right: -5%;
					//background: #fff;
					>img{
						font-size: 16px;
						color: rgb(248, 148, 18);
					}
				}
			}

			.childTypeLabel {
				width: 7.4vw;
				height: 4.2vw;
				margin: 0.5em;

			}
		}
	}

	.left {
		display: flex;
		align-items: center;
	}
}


</style>
