import {getManageUserInfo, logined} from "@/api/auth";
import {getToken, removeToken, setToken} from '@/utils/auth';

const localLoginPageQuery = localStorage.getItem('loginPageQuery')

const state = {
    token: getToken(),
    userInfo: null,
    userAuthList: null,
    typeId: null,
    loginSource: '', // 登录来源 ly | gm | cs | google
	/**
	 * 登录页面的一些参数
	 */
	loginPageQuery: localLoginPageQuery ? JSON.parse(localLoginPageQuery) :  {
		signOfGnGw: window.location.origin === 'https://o2o-manage-prod.gs-souvenir.com' ? 'gw' : 'gn', 	// gn 或 gw
		typeId: '',		 	// 企业微信Id
		exUserId: '', 		// 企业微信用户Id
		username: '',		// 企业微信用户名
		deparmentName: '',	// 企业微信用户部门
		source: '',			// 来源，ly | gm | cs | google
	}
}

const getters = {
	/**
	 * 是否第三方登录
	 */
	isThirdPartyLogin(state) {
		return !!state.loginPageQuery.exUserId
	},
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER_INFO: (state, info) => {
        state.userInfo = info
    },
    SET_USER_AUTHLIST: (state, authList) => {
        state.userAuthList = authList
    },
    SET_USER_TYPEID: (state, authList) => {
        state.typeId = authList
    },
    SET_LOGIN_SOURCE: (state, source) => {
        state.loginSource = source
    },
	SET_LOGIN_PAGE_QUERY: (state, query) => {
		// 参数去空
		for (let key in query) {
			if (!query[key] && query[key] !== 0 && query[key] !== false) delete query[key];
		}
		state.loginPageQuery = {
			...state.loginPageQuery,
			...query
		}

		localStorage.setItem('loginPageQuery', JSON.stringify(state.loginPageQuery))
	}
}

const actions = {
    login({commit}, userInfo) {
        const {userName, password} = userInfo
        return new Promise((resolve, reject) => {
            logined({userName: userName.trim(), password: password}).then(res => {
                const {data} = res
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // get user info
    getUserInfo({commit}) {
        return new Promise((resolve, reject) => {
            getManageUserInfo().then(res => {
                const {data} = res
                const {userInfo, userAuthList,typeId} = data
                commit('SET_USER_INFO', userInfo)
                commit('SET_USER_TYPEID', typeId)
                const rolesName = (userInfo.roles).map(item => item.roleName);
                //authType = 0页面权限，1接口权限
                let removeAdminBtnAuth = (list)=>{
                    if(!list.length){
                        return
                    }
                    list.forEach(item=>{
                        if(item.authType===1){
                            item.authorityName ='';
                        }
                        if(item.childList.length>0){
                            removeAdminBtnAuth(item.childList)
                        }
                    })
                }
                //去掉admin的接口权限
                if (rolesName.includes('admin')) {
                    removeAdminBtnAuth(userAuthList)
                }
                commit('SET_USER_AUTHLIST', userAuthList)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    logout({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
	getters,
    mutations,
    actions
}
