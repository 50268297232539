<template>
  <div class="fileUpload">
    <el-upload
        :class="isBtnStyle ? 'btnStyle' : 'avatar-uploader'"
        ref="uploadFile"
        action="#"
        :show-file-list="single ? false : showFileList"
        :accept="accept"
        :list-type="listType"
        :limit="limit"
        :http-request="httpRequest"
        :file-list="single ? [] : fileList"
        :multiple="multiple"
        :disabled="disabled"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-error="onError"
        :on-success="onSuccess"
        :on-progress="progress"
        :on-change="handleChange"
        :before-upload="beforeUpload"
        :before-remove="beforeRemove"
        :on-exceed="onExceed"
		:drag="drag"
    >
      <template v-if="single">
        <slot name="uploadBtn">
          <div v-if="fileList" class="avatarWrap">
            <custom-image :src="fileList" class="avatar" v-if="isImage" :isPreview="true"></custom-image>
            <custom-video :src="fileList" class="avatar" v-if="isVideo"></custom-video>
            <div class="btnGroup">
              <span @click.stop="handlePreviewAvatar"> {{ $t('inquiryPage.preview') }} </span>
              <span slot="trigger"> {{ $t('inquiryPage.uploading') }} </span>
            </div>
          </div>

          <div class="uploadBox avatar-uploader-icon" v-else>
<!--			  <div>-->
<!--				  <i :class="uploadBoxIcon"></i>-->
<!--				  <span>{{ uploadText }}</span>  -->
<!--			  </div>-->
			  <div class="text-wrap" v-if="type">
				  <i class="el-icon-document-add"></i>
				  <p>
					  Drag and drop your VAT Certificate
				  </p>
				  <span>
					PDF. JPEG. PNG _ 10MB file limit
				</span>
			  </div>
			  <div class="text" v-else>
				  <i :class="uploadBoxIcon"></i>
				  <span>{{ uploadText }}</span>
			  </div>
          </div>


        </slot>
      </template>
    </el-upload>
    <el-progress style="width: 200px;margin-top: 8px" :percentage="progressPercent" v-show="showProcess"/>
  </div>
</template>

<script>
import {uploadFile} from "@/utils/oss";
import {isImageType, isVideoType, replacePicPrefix} from "@/utils/utils"

export default {
  props: {
	  type: {
		  type: Boolean,
		  default: false,
	  },
    disabled: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "text",
    },
    limit: {
      type: [String, Number],
      default: 100,
    },
    srcList: [],
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    fileList: {
      type: [Array, String],
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    isBtnStyle: {
      type: Boolean,
      default: false,
    },
    uploadLimitSize: {
      type: Number,
      default: null,
    },
    uploadBoxIcon:{
      type: String,
      default: 'el-icon-plus'
    },
    uploadText:{
      type:String,
      default :null
      // default :'Upload Image'
    },
	  drag: {
		  type: Boolean,
		  default: false,
	  },
  },
  data() {
    return {
      centerDialogVisible: false,
      progressPercent: 0, // 进度条默认为0
      showProcess: false
    };
  },
  computed: {
    isImage() {
      if (!this.single) {
        return "";
      }
      return isImageType((this.fileList));
    },
    isVideo() {
      if (!this.single) {
        return "";
      }
      return isVideoType(this.fileList);
    },
  },

  methods: {
    handlePreviewAvatar() {
      if (!this.single) {
        return "";
      }
      if(this.isVideo) {
        this.$previewVideo(this.fileList);
      } 
      else{
        this.$viewerApi({
          images: [replacePicPrefix(this.fileList)]
        });
      }
    },
    handlePreview(file) {
      if (file.url) {
        window.open(replacePicPrefix(file.url), "_blank");
      }
    },
    handleRemove(file, fileList) {
      this.$refs.uploadFile.abort();
      this.$emit("updateList", fileList);
    },
    handleChange(file, fileList) {
      // if (file.status == 'ready') {
      //   this.progressPercent = 0
      //   this.showProcess = true
      //   const interval = setInterval(() => {
      //     if (this.progressPercent >= 99) {
      //       clearInterval(interval)
      //       return
      //     }
      //     this.progressPercent += 1
      //   },20)
      // }
      // if (file.status == 'success') {
      //
      // }
    },
    beforeUpload(file) {
    },
    beforeRemove(file, fileList) {
    },
    onError(err, file, fileList) {
    },
    onExceed(files, fileList) {
      this.$message.error("Max Length" + " " + this.limit);
    },
    onSuccess(response, file, fileList) {
      this.$emit("updateList", fileList);
    },
    progress(event, file, fileList) {
      let loadProgress = Math.floor(event.percent)
      this.progressPercent = loadProgress || 0
    },
    httpRequest(file) {
      const fileType = file.file.name.split('.').pop()
      if (this.accept) {
        let res = this.accept.replace(/image\//g, '')?.split(',');
        // console.log(res); // "[jpeg,jpg,gif,png]"
        let findItem = res.find(i => i.toUpperCase() == fileType.toUpperCase())
        console.log("findItem", findItem);
        if (!findItem) {
          // this.$message.error('只支持JPG、PNG、GIF')
          this.$message.error(`只支持${res.join('、').toUpperCase()}格式`)
          this.$refs.uploadFile.uploadFiles.splice(0, 1)
          return
        }
      }
      if (this.uploadLimitSize) {
        const isLt5M = file.file.size / 1024 / 1024 < this.uploadLimitSize
        if (!isLt5M) {
          this.$message({
            message: `导入文件不能超过${this.uploadLimitSize}M的`,
            type: 'warning'
          })
          return
        }
      }

      this.showProcess = true;
      uploadFile(file.file, (progressEvent) => {
        let num = Math.floor((progressEvent.loaded / progressEvent.total) * 100 || 0);
        this.progressPercent = num
      })
          .then((res) => {
            this.$emit("updateList", [
              {
                response: res
              }
            ]);
          })
          .finally(() => {
            this.showProcess = false;
            this.progressPercent = 0;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnStyle ::v-deep {
  .el-upload {
    border: none
  }
}

.avatar-uploader ::v-deep {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
    flex-direction: column;
  }

  .avatarWrap {
    width: 178px;
    height: 178px;
    position: relative;

    .btnGroup {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.3s;
      color: #fff;
      opacity: 0;

      span {
        margin: 0 10px;
      }
    }

    &:hover {
      .btnGroup {
        opacity: 1;
      }
    }
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: contain;
  }
  .uploadBox{
	  position: relative;
	  .text{
		  i{
			  // font-size: 28px;
			  font-size: 38px;
		  }
		  span{
			  display: block;
			  font-size: 12px;
		  }
	  }
	  .text-wrap{
		  display: block;
		  position: absolute;
		  text-align: center;
		  cursor: pointer;
		  i{
			  font-size: 36px;
			  color: #3D3D3D;
		  }
		  p{
			  color: #3D3D3D;
			  margin-bottom: 0px;
		  }
		  span{
			  color: #bebebe;
		  }
	  }
  }

}
</style>
