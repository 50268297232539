import layout from "@/views/layout/index.vue";

const memberManage = () => import("@/views/userCenter/memberManage.vue");
const memberLevel = () => import("@/views/userCenter/memberLevel.vue");
const memberAddress = () => import("@/views/userCenter/memberAddress.vue");
export default {
    path: "/userCenter",
    name: "userCenter",
    component: layout,
    meta: {
        title: "用户管理",
        icon: "el-icon-user-solid"
    },
    children: [{
        path: "memberManage",
        component: memberManage,
        name: "memberManage",
        meta: {
            title: "会员信息管理"
        }
    },
        {
            path: "memberLevel",
            component: memberLevel,
            name: "memberLevel",
            meta: {
                title: "会员等级管理"
            }
        },
		{
            path: "memberAddress",
            component: memberAddress,
            name: "memberAddress",
            meta: {
                title: "经销商地址管理"
            }
        }
    ]
}
