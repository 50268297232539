<template>
	<div id="dzApp">
		<div id="dsWrap">
			<dz-head @closeDialog="closeDialog"></dz-head>
			<div class="ds-content">
				<div class="left-area-wrap">
					<left-area v-if="show"></left-area>
				</div>
				<div class="ds-right-area" @click="showDefault">
					<div class="sideWrap" :class="{isSticker:canvasWorker.isSticker}">
						<side v-if="show" :class="{ visibilityHidden: isDefault, isYd: editHelp }"></side>
					</div>
					<div class="ds-right-content">
						<div class="tool-bar-wrap">
							<tool-bar v-if="show" :class="{ visibilityHidden: isDefault, isYd: editHelp }"></tool-bar>
						</div>
						<div class="fbWrap" id="designBody" :class="{isSticker:canvasWorker.isSticker}">
							<div class="des" :class="{ visibilityHidden: !canvasWorker.templateName }">
								{{canvasWorker.templateName}}
							</div>
							<div id="workspace" v-loading="loading">
								<canvas id="fabricCanvas"></canvas>
							</div>
							<zoom class="zoom" v-if="show"></zoom>
							<mouseMenu v-if="show"></mouseMenu>
						</div>
					</div>
					<div class="right-tool-wrap" v-if="!isMb">
						<right-tool v-if="show" :class="{ visibilityHidden: isDefault, isYd: editHelp }"></right-tool>
					</div>
					<!-- 模板列表-->
					<template-list v-if="show && !isMb" @templateId="getId"></template-list>
					<!-- 元素列表-->
					<art-list v-if="show && !isMb"></art-list>
				</div>
				<div class="bottomAreaWrap" v-if="!isMb">
					<bottom-area v-if="show" :designData="designData" @closeDialog="closeDialog" :templateId="templateId"></bottom-area>
				</div>
			</div>
		</div>
		<change-Element v-if="showReplaceDialog" :width="device !== 'mb' ? '710px' : '92%'"></change-Element>
		<tool-option v-if="show" ref="toolOption"></tool-option>
		<dzYd v-if="editHelp && show && !isMb" @closeEditHelp="closeEditHelp"></dzYd>
		<myDesign :myDesignDialog.sync="myDesignDialog" v-if="myDesignDialog"></myDesign>
	</div>
</template>
<script>
import myDesign from "@/components/MyDzxt/myDesign.vue";
import leftArea from "@/components/MyDzxt/leftArea.vue";
import dzHead from "@/components/MyDzxt/dzHead.vue";
import ColorPickerItem from "@/components/MyDzxt/ColorPickerItem.vue";
import zoom from "@/components/MyDzxt/zoom.vue";
import side from "@/components/MyDzxt/side.vue";
import toolBar from "@/components/MyDzxt/toolBar.vue";
import rightTool from "@/components/MyDzxt/rightTool.vue";
import bottomArea from "@/components/MyDzxt/bottomArea.vue";
import templateList from "@/components/MyDzxt/templateList.vue";
import artList from "@/components/MyDzxt/artList.vue";
import toolOption from "@/components/MyDzxt/toolOption.vue";
import changeElement from "@/components/MyDzxt/changeElement.vue";
import canvas from "@/assets/js/fabricCore/dzPublic";
import dzYd from "@/components/MyDzxt/dzYd.vue";
import { deepClone, loadCss } from "@/utils/utils";
import { getById, manageGetTemplateById, getAllColorCard, getAllColorType } from "@/api/newDzxt";
// 右键菜单
import mouseMenu from "@/components/MyDzxt/contextMenu/index.vue";

export default {
	props: ["designData"],
	computed: {
		isDefault() {
			return this.$store.state.design.isDefault;
		},
		loading() {
			return this.$store.state.design.loading;
		},
		device() {
			return this.$store.state.device;
		},
		langDesign() {
			return this.$store.getters.lang?.design;
		},
		isMb() {
			return this.$store.state.device === "mb";
		},
		config() {
			return canvas.config;
		},
		showReplaceDialog() {
			return this.$store.state.design.showReplaceDialog;
		}
	},
	data() {
		return {
			myDesignDialog: false,
			canvasWorker: canvas,
			editHelp: false,
			signUpDialog: false,
			canvas: null,
			hideTool: false,
			workspace: null,
			show: false,
			workspaceEl: null,
			templateId: undefined
		};
	},
	provide() {
		return {
			canvas: this.canvasWorker
		};
	},
	components: {
		myDesign,
		rightTool,
		toolBar,
		zoom,
		ColorPickerItem,
		dzHead,
		leftArea,
		side,
		bottomArea,
		templateList,
		artList,
		toolOption,
		dzYd,
		changeElement,
		mouseMenu
	},
	methods: {
		//MyDesign弹窗
		showMyDesign() {
			this.myDesignDialog = true;
		},
		closeDialog() {
			this.canvasWorker.templateName='';
			this.$emit("closeDialog");
		},
		showDefault(e) {
			let target = e.target
			if(target.tagName !=='CANVAS'){
				let topBar = document.querySelector('.tool-bar'); // 获取目标容器元素
				if (topBar && topBar.contains(target)) {
					return
				}
				this.$Bus.$emit('showDefault')
				this.$Bus.$emit('hideTools')
				this.$nextTick(() => {
					this.canvas.discardActiveObject().requestRenderAll()
				})
			}
		},
		closeEditHelp() {
			this.editHelp = false;
		},
		confirmExit(event) {
			event.preventDefault();
			event.returnValue = "确定要离开网页吗？";
		},
		//初始化画布
		initCanvas() {
			this.canvas = canvas.initCanvas("fabricCanvas");
			this.canvas.on({
				"object:added": e => {
					if (e.target.id !== "workspace") {
						this.$store.commit("design/set_isDefault", false);
					}
				}
			});
		},
		handleResize() {
			canvas.auto();
		},
		getId(val) {
			this.templateId = val;
		}
	},
	created() {
		getAllColorCard({
			language: "en-us"
		}).then(res => {
			this.$store.commit("design/set_colorList", res.data);
		});
		getAllColorType({
			language: "en-us"
		}).then(res => {
			this.$store.commit("design/set_colorType", res.data);
		});
	},
	async mounted() {
		await this.$nextTick();
		let urlData;
		if (this.designData.categoryId == 0) {
			urlData = {
				id: 0,
				productType: 0,
				positionConfigs: [
					{
						id: null,
						categoryId: null,
						designPostion: 0,
						positionColorLimitation: null,
						positionCopywriting: "common"
					}
				]
			};
		} else {
			urlData = (
				await getById({
					id: this.designData.categoryId,
					language: "en-us"
				})
			).data;
		}
		let positionConfigs = deepClone(urlData.positionConfigs);
		positionConfigs.forEach(item => {
			item.url = "";
			item.templateData = null;
		});
		canvas.positionConfigs = positionConfigs;
		canvas.hasBg = urlData.hasBg;
		canvas.isSticker = urlData.isSticker;
		canvas.stickerEffectType = urlData.stickerEffectType;
		this.$store.commit("design/set_pageInfo", urlData);
		this.initCanvas();
		//是半定制还是全定制
		let productType = urlData.productType;
		if (productType === 1) {
			//半定制暂时不做处理
			canvas.designDes = "half";
		} else if (productType === 0) {
			canvas.designDes = "quote";
		}
		//判断是否是编辑模板
		if (this.designData.tid) {
			let data = await manageGetTemplateById({ id: this.designData.tid, language: "en-us" });
			if(canvas.isSticker){
				let specification = JSON.parse(data.data.specification)
				canvas.stickerSize = {
					w:specification.width,
					h:specification.height
				}
			}
			this.templateId = this.designData.tid;
			await canvas.loadTemplate(data.data.templateFile);
		}
		window.addEventListener("beforeunload", this.confirmExit);
		window.addEventListener("resize", this.handleResize);
		this.show = true;
		loadCss("//at.alicdn.com/t/c/font_3787566_wejkaroajb.css"); //黑色
		loadCss("//at.alicdn.com/t/c/font_3787572_ovbvnsxlgfe.css"); //彩色
		loadCss("https://fonts.googleapis.com/css2?family=Acme&family=Amaranth&family=Anton&family=Boogaloo&family=Chewy&family=Concert+One&family=Courgette&family=Fjalla+One&family=Francois+One&family=Fredoka+One&family=Fugaz+One&family=Great+Vibes&family=Hanalei+Fill&family=Kaushan+Script&family=Lobster+Two&family=MedievalSharp&family=Oleo+Script+Swash+Caps&family=Oswald:wght@200&family=Pacifico&family=Palanquin+Dark&family=Passion+One&family=Permanent+Marker&family=Ranga&family=Righteous&family=Roboto:wght@100&family=Ruslan+Display&family=Sigmar+One&family=Timmana&family=Viga&family=Quicksand&display=swap");
		if (!this.isMb) {
			await canvas.showGrid();
		}
		this.$Bus.$on('showMyDesign',this.showMyDesign);
	},
	beforeDestroy() {
		this.canvas.off();
		window.removeEventListener("resize", this.handleResize);
		window.removeEventListener("beforeunload", this.confirmExit);
		this.$Bus.$off('showMyDesign',this.showMyDesign);
	}
};
</script>
<style lang="scss" scoped>
#dzApp {
	position: relative;
	font-family: Calibri, sans-serif;
	color: #333333;

	::v-deep {
		.linear-gradient-1 {
			background: repeating-linear-gradient(-45deg, #f4f5f6, #f4f5f6 5px, #f8f9fa 0, #f8f9fa 40px) !important;
			background-color: #f4f5f6;
		}

		.visibilityHidden {
			visibility: hidden;
		}

		.isYd.visibilityHidden {
			visibility: visible;
		}
	}
}

#dsWrap {
	overflow: auto;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100vh;
	user-select: none;
	font-size: 16px;

	@include respond-to(mb) {
		font-size: 14px;
		min-height: auto;
	}
}

.ds-content {
	overflow: auto;
	position: relative;
	display: grid;

	grid-template-columns: minmax(500px, 630px) 1fr;

	&.disabledPage {
		pointer-events: none;
	}

	.left-area-wrap {
		min-width: 0;
		height: calc(100vh - 60px);
	}

	.ds-right-area {
		overflow: hidden auto;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		background-color: #e6e6e6;
		height: calc(100vh - 140px);
		min-width: 950px;
		padding: 0 1rem 20px;

		.sideWrap {
			width: 90px;
			margin-right: 10px;

			&.isSticker {
				visibility: hidden;
			}
		}

		.ds-right-content {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			position: relative;
			height: 100%;

			.tool-bar-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 60px;
			}

			& > div {
				width: 100%;
			}

			.fbWrap {
				width: 700px;
				display: flex;
				flex-direction: column;
				flex: 1;
				height: 0;
				position: relative;
				background-color: #ffffff;
				padding: 0 50px 65px;

				&.isSticker {
					background-color: transparent;
				}

				.des {
					margin: 10px 0;
					font-weight: 700;
				}

				#workspace {
					flex: 1;
					height: 0;

					.mbLeftTool {
						display: none;
					}

					.mbRightTool {
						display: none;
					}
				}

				.zoom {
					position: absolute;
					bottom: 10px;
					right: 10px;
				}

				.space {
					display: none;
					flex: 1;
				}

				.mb-control-area {
					display: none;
				}
			}
		}

		.right-tool-wrap {
			width: 80px;
		}

		.bottomAreaWrap {
			height: 80px;
		}
	}

	@include respond-to(mb) {
		display: block;

		.tool-bar-wrap {
			display: none !important;
		}

		.left-area-wrap {
			display: none;
		}

		.sideWrap {
			display: none;
		}

		.right-tool-wrap {
			display: none;
		}

		.bottomAreaWrap {
			display: none;
		}

		.ds-right-area {
			min-width: 100%;
			width: 100%;
			height: calc(100vh - 44px);
			padding: 0;

			.ds-right-content {
				width: 100%;

				.fbWrap {
					width: 100%;
					height: 100%;
					padding: 0;
					background: #ffffff url("https://static-oss.gs-souvenir.com/web/quoteManage/20230825/grid_202308258HJxrf.png") repeat left top;

					.des {
						text-align: center;
						font-size: 12px;
					}

					#workspace {
						flex: 0 0 350px;
						position: relative;

						.mbLeftTool {
							display: block;
							position: absolute;
							top: 1rem;
							left: 0.5rem;
						}

						.mbRightTool {
							display: block;
							position: absolute;
							top: 1rem;
							right: 0.5rem;
						}
					}

					.zoom {
						display: none;
					}

					.space {
						display: block;
						flex: 1;
					}

					.mb-control-area {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding: 10px 0;
						height: 220px;

						.plating-area {
							position: relative;
							height: 80px;
							margin-bottom: 40px;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="scss">
.el-select-dropdown {
	border-color: #2a96fa;
}

.el-select-dropdown.el-popper .popper__arrow {
	border-bottom-color: #2a96fa;
}

.el-button--primary {
	color: #fff;
	background-color: #2a96fa;
	border-color: #2a96fa;
}

.el-select .el-input.is-focus .el-input__inner {
	border-color: #2a96fa;
}

.el-select-dropdown__item.selected {
	color: #2a96fa;
}

.editColor-colorPicker {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;

	.editColor-colorPicker-title {
	}

	.editColor-colorPicker-selected {
		display: flex;
		align-items: center;
		margin: 12px 0;

		.color-name {
			margin-left: 8px;
		}
	}

	.editColor-colorPicker-colorContainer {
		flex: 1;
		overflow: hidden auto;

		.editColor-colorPicker-colors {
			display: grid;
			grid-template-columns: repeat(auto-fill, 2.875rem);
			grid-column-gap: 10px;
			grid-row-gap: 10px;
		}
	}

	@media screen and (max-width: 768px) {
		.editColor-colorPicker-colorContainer {
			.editColor-colorPicker-colors {
				display: grid;
				grid-template-columns: repeat(auto-fill, 34px);
				grid-column-gap: 6px;
				grid-row-gap: 10px;
			}
		}
	}
}

.more-popover {
	min-width: auto;
	user-select: none;

	.more-popover-item {
		margin-bottom: 6px;

		a {
			white-space: nowrap;
		}

		&:last-child {
			margin-bottom: 0;
		}

		b {
			margin-right: 8px;
		}

		&:hover {
			color: #2a96fa;
			text-decoration: underline;
		}
	}
}

.layer-popover {
	padding: 12px 0 0;
	user-select: none;

	.el-popover__title {
		padding: 0 12px;
	}

	.list .list-item {
		display: flex;
		align-items: center;
		height: 30px;
		padding: 0 12px;
		cursor: pointer;

		b {
			margin-right: 4px;
		}

		&:hover {
			background: #f4f5f6;
		}
	}
}

.pen-popover {
	.penWrap {
		.top {
			display: flex;
			align-items: center;

			.el-slider {
				flex: 1;
				margin: 0 20px;
			}

			.el-slider .el-slider__input {
				width: 70px;
			}

			.colorPicker {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				width: 40px;
				height: 40px;
				background: #ffffff;
				border: 1px solid #e6e6e6;
				cursor: pointer;
				border-radius: 4px;

				.color-inner {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 34px;
					height: 34px;
					background: #da9f19;
					border-radius: 4px;

					b {
						font-size: 14px;
						color: #ffffff;
					}
				}
			}
		}

		.btnWrap {
			display: flex;
			justify-content: center;
			margin-top: 10px;

			.startBtn {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 20px;
				cursor: pointer;

				&:hover {
					color: #2a96fa;
				}
			}
		}
	}
}

.color-popover {
	width: 540px;

	.colorWrap {
		display: grid;
		grid-template-columns: repeat(auto-fill, 2.875rem);
		grid-column-gap: 5px;
		grid-row-gap: 5px;
		overflow: hidden auto;
		max-height: 302px;
	}

	@media screen and (max-width: 768px) {
		width: 304px;
		padding: 10px;

		.colorWrap {
			grid-template-columns: repeat(auto-fill, 34px);
			grid-column-gap: 7px;
			grid-row-gap: 10px;
			max-height: 122px;
		}
	}
}

.img-color-popover {
	width: 480px;

	.editColor-colorPicker-selected {
		display: grid;
		grid-template-columns: repeat(auto-fill, 28px);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		max-height: 110px;
		overflow: hidden auto;
	}

	.editColor-colorPicker-colorContainer {
		margin-top: 10px;
	}

	.editColor-colorPicker-colors {
		grid-template-columns: repeat(auto-fill, 28px) !important;
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		max-height: 110px;
		overflow: hidden auto;
	}

	.editImgColor-foot {
		padding: 1rem;
		display: none;
		justify-content: center;
		align-items: center;
	}

	.color-item {
		width: 28px;
		height: 28px;
	}
}

.text-color-popover {
	width: 530px;

	.colorWrap {
		display: grid;
		grid-template-columns: repeat(auto-fill, 28px);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		max-height: 110px;
		overflow: hidden auto;
	}

	.color-item {
		width: 28px;
		height: 28px;
	}
}

.el-slider {
	.el-slider__runway.show-input {
		margin-right: 110px;
	}

	.el-slider__input {
		width: 90px;
		border-radius: 6px;

		input {
			background: #f5f5f5;
		}
	}
}

.dzToolTip {
	border-color: #ffffff !important;
	background-color: #ffffff;
	box-shadow: 0px 1px 6px 0px rgba(179, 179, 179, 0.64);

	.popper__arrow {
		border-bottom-color: #ffffff !important;
	}
}

.stickerSizeToolTip {
	border-color: #FF4242 !important;
	background-color: #FF4242;
	box-shadow: 0px 1px 6px 0px rgba(179, 179, 179, 0.64);
	font-size: 12px;
	padding: 5px;
	color: #FFFFFF;
	max-width: 120px;
	min-width: auto;
	white-space: nowrap;
	text-align: center;
	font-family: Calibri, Arial, serif;

	.popper__arrow {
		border-bottom-color: #FF4242 !important;
		border-top-color: #FF4242 !important;

		&::after {
			border-top-color: #FF4242 !important;
		}
	}
}

@media screen and (max-width: 768px) {
	.el-message-box {
		width: 95%;
	}
}
</style>
