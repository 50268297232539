import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue");
const plantParamConfig = () => import("@/views/NewProduction/plant/plantParamConfig.vue");
const plantProjectManage = () => import("@/views/NewProduction/plant/plantProjectManage/index.vue");
const parameterManagement = () => import("@/views/NewProduction/plant/plantProjectManage/parameterManagement.vue");
const SemiCustomProduct = () => import("@/views/NewProduction/plant/plantProjectManage/SemiCustomProduct.vue");
const platformManagement = () => import("@/views/NewProduction/plant/plantProjectManage/platformManagement.vue");
const productManagement = () => import("@/views/NewProduction/plant/plantProjectManage/productManagement.vue");
const websiteManagement = () => import("@/views/NewProduction/plant/plantProjectManage/websiteManagement.vue");
const tab = () => import("@/views/NewProduction/plant/plantProjectManage/tab.vue");
// 工厂质检参数管理
const factoryParameterManagement = () => import("@/views/NewProduction/plant/factoryParameterManagement/index.vue");
const factoryQualityControlParameter = () =>
	import("@/views/NewProduction/plant/factoryParameterManagement/factoryQualityControlParameter.vue");
const factoryProductManagement = () =>
	import("@/views/NewProduction/plant/factoryParameterManagement/factoryProductManagement.vue");
const factory = () => import("@/views/NewProduction/plant/factoryParameterManagement/tab.vue");
// 采购参数管理
const PurchasingParameManagement = () => import("@/views/NewProduction/PurchasingParameManagement/index.vue");
const PurTab = () => import("@/views/NewProduction/PurchasingParameManagement/tab.vue");
const PurchaseTypeManagement = () =>
	import("@/views/NewProduction/PurchasingParameManagement/PurchaseTypeManagement.vue");
const ProcurementLibraryManage = () =>
	import("@/views/NewProduction/PurchasingParameManagement/ProcurementLibraryManage.vue");
// 生产单
const ProjectConfiguration = () => import("@/views/NewProduction/configurationManagement/ProjectConfiguration.vue");
const ProjectType = () => import("@/views/NewProduction/configurationManagement/ProjectType.vue");
const NewManage = () => import("@/views/NewProduction/moManage.vue");
const invoice = () => import("@/views/NewProduction/invoice.vue");
const printAndDispatchInvoice = () => import("@/views/print/printAndDispatchInvoice.vue");
const newList = () => import("@/views/NewProduction/newList.vue");
const ParamsManage = () => import("@/views/NewProduction/ParamsManage.vue");
const newPurchase = () => import("@/views/NewProduction/newPurchase.vue");
const ParamsTypeManage = () => import("@/views/NewProduction/ParamsTypeManage.vue");
const createAbnormalOrder = () => import("@/views/NewProduction/createAbnormalOrder.vue");
const createAbnormalPart = () => import("@/views/NewProduction/createAbnormalPart.vue");
const hasbeenShipped = () => import("@/views/NewProduction/hasbeenShipped.vue");
const kunshanProduct = () => import("@/views/NewProduction/ProductionOrderStatistics/kunshanProduct.vue");
const changdeProduct = () => import("@/views/NewProduction/ProductionOrderStatistics/changdeProduct.vue");
const ribbonstatistics = () => import("@/views/NewProduction/ProductionOrderStatistics/ribbonstatistics.vue");
const Purchasestatistics = () => import("@/views/NewProduction/ProductionOrderStatistics/Purchasestatistics.vue");
const sticker_statistics = () => import("@/views/NewProduction/ProductionOrderStatistics/sticker_statistics.vue");
const neon_sign_statistics = () => import("@/views/NewProduction/ProductionOrderStatistics/neon_sign_statistics.vue");
const pvc_statistics = () => import("@/views/NewProduction/ProductionOrderStatistics/pvc_statistics.vue");
const purchaseAllocation = () => import("@/views/NewProduction/purchaseAllocation.vue");
const orderStaffing = () => import("@/views/NewProduction/orderStaffing.vue");
export default {
	path: "/NewProduction",
	name: "NewProduction",
	component: layout,
	meta: {
		title: "生产单管理",
		icon: "el-icon-s-grid",
	},
	children: [
		{
			path: "plant",
			name: "plant",
			component: customIndex,
			meta: {
				title: "工厂报价参数管理",
				icon: "el-icon-coin",
			},
			children: [
				{
					path: "plantParamConfig",
					component: plantParamConfig,
					name: "plantParamConfig",
					meta: {
						title: "报价参数列表",
					},
				},
				{
					path: "plantProjectManage",
					name: "plantProjectManage",
					component: plantProjectManage,
					redirect: {
						name: "tab",
					},
					meta: {
						title: "参数库配置",
					},
					children: [
						{
							path: "tab",
							name: "tab",
							component: tab,
							redirect: {
								name: "platformManagement",
							},
							hidden: true,
							children: [
								{
									path: "platformManagement",
									name: "platformManagement",
									component: platformManagement,
									meta: {
										title: "平台管理",
									},
								},
								{
									path: "websiteManagement",
									name: "websiteManagement",
									component: websiteManagement,
									meta: {
										title: "网站管理",
									},
								},
								{
									path: "productManagement",
									name: "productManagement",
									component: productManagement,
									meta: {
										title: "产品管理",
									},
								},
								{
									path: "parameterManagement",
									name: "parameterManagement",
									component: parameterManagement,
									meta: {
										title: "参数管理",
									},
								},
								{
									path: "SemiCustomProduct",
									name: "SemiCustomProduct",
									component: SemiCustomProduct,
									meta: {
										title: "半定制产品配重",
									},
								},
							],
						},
					],
				},
				{
					path: "factoryParameterManagement",
					component: factoryParameterManagement,
					name: "factoryParameterManagement",
					redirect: {
						name: "factory",
					},
					meta: {
						title: "工厂质检参数管理",
					},
					children: [
						{
							path: "factory",
							name: "factory",
							component: factory,
							redirect: {
								name: "factoryProductManagement",
							},
							hidden: true,
							children: [
								{
									path: "factoryProductManagement",
									name: "factoryProductManagement",
									component: factoryProductManagement,
									meta: {
										title: "产品管理",
									},
								},
								{
									path: "factoryQualityControlParameter",
									name: "factoryQualityControlParameter",
									component: factoryQualityControlParameter,
									meta: {
										title: "参数管理",
									},
								},
							],
						},
					],
				},
			],
		},
		{
			path: "PurchasingParameManagement",
			name: "PurchasingParameManagement",
			component: PurchasingParameManagement,
			redirect: {
				name: "PurTab",
			},
			meta: {
				title: "采购参数管理",
				icon: "el-icon-receiving",
			},
			children: [
				{
					path: "PurTab",
					name: "PurTab",
					component: PurTab,
					redirect: {
						name: "PurchaseTypeManagement",
					},
					hidden: true,
					children: [
						{
							path: "PurchaseTypeManagement",
							name: "PurchaseTypeManagement",
							component: PurchaseTypeManagement,
							meta: {
								title: "采购类型管理",
							},
						},
						{
							path: "ProcurementLibraryManage",
							name: "ProcurementLibraryManage",
							component: ProcurementLibraryManage,
							meta: {
								title: "参数库管理",
							},
						},
					],
				},
			],
		},

		{
			path: "configurationManagement",
			name: "configurationManagement",
			component: customIndex,
			meta: {
				title: "配置管理",
				icon: "el-icon-s-tools",
			},
			children: [
				{
					path: "ProjectConfiguration",
					component: ProjectConfiguration,
					name: "ProjectConfiguration",
					meta: {
						title: "项目配置",
					},
				},
				{
					path: "ProjectType",
					component: ProjectType,
					name: "ProjectType",
					meta: {
						title: "项目类型配置",
					},
				},
			],
		},

		{
			path: "moManage",
			name: "moManage",
			component: NewManage,
			meta: {
				title: "生产单列表",
				type: 0,
			},
		},
		{
			path: "createAbnormalOrder",
			name: "createAbnormalOrder",
			component: createAbnormalOrder,
			hidden: true,
			meta: {
				title: "问题单",
			},
		},
		{
			path: "createAbnormalPart",
			name: "createAbnormalPart",
			component: createAbnormalPart,
			hidden: true,
			meta: {
				title: "新建异常件",
			},
		},
		{
			path: "newList",
			name: "newList",
			component: newList,
			hidden: true,
			meta: {
				title: "生产订单详情",
			},
		},
		{
			path: "invoice",
			name: "invoice",
			hidden: true,
			meta: {
				title: "发货订单详情",
			},
			component: invoice,
		},
		{
			path: "newPurchase",
			name: "newPurchase",
			meta: {
				title: "采购订单详情",
			},
			hidden: true,
			component: newPurchase,
		},
		{
			path: "ParamsManage",
			name: "ParamsManage",
			component: ParamsManage,
			meta: {
				title: "公共参数管理",
				type: 0,
			},
		},
		{
			path: "ParamsTypeManage",
			name: "ParamsTypeManage",
			component: ParamsTypeManage,
			meta: {
				title: "参数类型管理",
				type: 0,
			},
		},

		{
			path: "hasbeenShipped",
			name: "hasbeenShipped",
			component: hasbeenShipped,
			meta: {
				title: "采购商管理",
			},
		},
		{
			path: "/ProductionOrderStatistics",
			name: "ProductionOrderStatistics",
			component: customIndex,
			meta: {
				title: "生产单统计",
				icon: "el-icon-data-line",
			},
			children: [
				{
					path: "kunshanProduct",
					component: kunshanProduct,
					name: "kunshanProduct",
					meta: {
						title: "昆山生产单统计",
					},
				},
				{
					path: "changdeProduct",
					component: changdeProduct,
					name: "changdeProduct",
					meta: {
						title: "常德生产单统计",
					},
				},
				{
					path: "ribbonstatistics",
					component: ribbonstatistics,
					name: "ribbonstatistics",
					meta: {
						title: "织带生产单统计",
					},
				},
				{
					path: "sticker_statistics",
					component: sticker_statistics,
					name: "sticker_statistics",
					meta: {
						title: "贴纸生产单统计",
					},
				},
				{
					path: "neon_sign_statistics",
					component: neon_sign_statistics,
					name: "neon_sign_statistics",
					meta: {
						title: "霓虹灯生产单统计",
					},
				},
				{
					path: "pvc_statistics",
					component: pvc_statistics,
					name: "pvc_statistics",
					meta: {
						title: "PVC生产单统计",
					},
				},
				{
					path: "Purchasestatistics",
					component: Purchasestatistics,
					name: "Purchasestatistics",
					meta: {
						title: "采购单统计",
					},
				},
			],
		},
		{
			path: "purchaseAllocation",
			name: "purchaseAllocation",
			component: purchaseAllocation,
			meta: {
				title: "采购员配置",
			},
		},
		{
			path: "orderStaffing",
			name: "orderStaffing",
			component: orderStaffing,
			meta: {
				title: "下单员配置",
			},
		},
	],
};
