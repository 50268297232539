import layout from "@/views/layout/index.vue";
const customIndex = () => import("@/views/index.vue");
/**
 * 数据分析
 * 销售数据
 */

const salesDataAnalysisOverview = () => import('@/views/dataAnalysis/sale/salesDataAnalysisOverview/index.vue')
const categoryDataAnalysisChart = () => import('@/views/dataAnalysis/sale/categoryDataAnalysis/myChart.vue')
const platformDataAnalysisChart = () => import('@/views/dataAnalysis/sale/platformDataAnalysis/myChart.vue')
const countryDataAnalysisChart = () => import('@/views/dataAnalysis/sale/countryDataAnalysis/myChart.vue')
const peopleDataAnalysisChart = () => import('@/views/dataAnalysis/sale/peopleDataAnalysis/myChart.vue')

const categoryDataAnalysisTable = () => import('@/views/dataAnalysis/sale/categoryDataAnalysis/myTable.vue')
const platformDataAnalysisTable = () => import('@/views/dataAnalysis/sale/platformDataAnalysis/myTable.vue')
const countryDataAnalysisTable = () => import('@/views/dataAnalysis/sale/countryDataAnalysis/myTable.vue')
const peopleDataAnalysisTable = () => import('@/views/dataAnalysis/sale/peopleDataAnalysis/myTable.vue')

/**
 * 数据分析
 * 询盘数据
 */

const inquiryDataAnalysisOverview = () => import('@/views/dataAnalysis/inquiry/salesDataAnalysisOverview/index.vue')
const inquiryCategoryDataAnalysisChart = () => import('@/views/dataAnalysis/inquiry/categoryDataAnalysis/myChart.vue')
const inquiryplatformDataAnalysisChart = () => import('@/views/dataAnalysis/inquiry/platformDataAnalysis/myChart.vue')
const inquiryCountryDataAnalysisChart = () => import('@/views/dataAnalysis/inquiry/countryDataAnalysis/myChart.vue')

const inquiryCategoryDataAnalysisTable = () => import('@/views/dataAnalysis/inquiry/categoryDataAnalysis/myTable.vue')
const inquiryPlatformDataAnalysisTable = () => import('@/views/dataAnalysis/inquiry/platformDataAnalysis/myTable.vue')
const inquiryCountryDataAnalysisTable = () => import('@/views/dataAnalysis/inquiry/countryDataAnalysis/myTable.vue')

// 权限配置
const configView = () => import('@/views/dataAnalysis/configView/index.vue')

export default {
    path: "/dataAnalysis",
    name: "dataAnalysis",
    component: layout,
    meta: {
        title: "数据分析",
        icon: "el-icon-data-line"
    },
    children: [
        {
            path: "saleDataAnalysis",
            name: "saleDataAnalysis",
            component: customIndex,
            meta: {
                title: "销售数据分析"
            },
            children: [
                {
                    path: "salesDataAnalysisOverview",
                    name: "salesDataAnalysisOverview",
                    component: salesDataAnalysisOverview,
                    meta: {
                        title: "概况"
                    },
                },
                {
                    path: "categoryDataAnalysisChart",
                    name: "categoryDataAnalysisChart",
                    component: categoryDataAnalysisChart,
                    meta: {
                        title: "品类数据分析",
                        activeMenu:'/dataAnalysis/saleDataAnalysis/categoryDataAnalysisTable'
                    },
                },
                {
                    path: "platformDataAnalysisChart",
                    name: "platformDataAnalysisChart",
                    component: platformDataAnalysisChart,
                    meta: {
                        title: "平台数据分析",
                        activeMenu:'/dataAnalysis/saleDataAnalysis/platformDataAnalysisTable'
                    },
                },
                {
                    path: "countryDataAnalysisChart",
                    name: "countryDataAnalysisChart",
                    component: countryDataAnalysisChart,
                    meta: {
                        title: "国家数据分析",
                        activeMenu:'/dataAnalysis/saleDataAnalysis/countryDataAnalysisTable'
                    },
                },
                {
                    path: "peopleDataAnalysisChart",
                    name: "peopleDataAnalysisChart",
                    component: peopleDataAnalysisChart,
                    meta: {
                        title: "人群数据分析",
                        activeMenu:'/dataAnalysis/saleDataAnalysis/peopleDataAnalysisTable'
                    },
                },
                {
                    path: 'categoryDataAnalysisTable',
                    name: 'categoryDataAnalysisTable',
                    component: categoryDataAnalysisTable,
                    meta: {
                        title: '品类数据分析图表'
                    }
                },
                {
                    path: 'platformDataAnalysisTable',
                    name: 'platformDataAnalysisTable',
                    component: platformDataAnalysisTable,
                    meta: {
                        title: '平台数据分析图表'
                    }
                },
                {
                    path: 'countryDataAnalysisTable',
                    name: 'countryDataAnalysisTable',
                    component: countryDataAnalysisTable,
                    meta: {
                        title: '国家数据分析图表'
                    }
                },
                {
                    path: 'peopleDataAnalysisTable',
                    name: 'peopleDataAnalysisTable',
                    component: peopleDataAnalysisTable,
                    meta: {
                        title: '人群数据分析图表'
                    }
                },
            ]
        },
        {
            path: "inquiryAnalysis",
            name: "inquiryAnalysis",
            component: customIndex,
            meta: {
                title: "询盘数据分析"
            },
            children: [
                {
                    path: "inquiryDataAnalysisOverview",
                    name: "inquiryDataAnalysisOverview",
                    component: inquiryDataAnalysisOverview,
                    meta: {
                        title: "概况"
                    },
                },
                {
                    path: "inquiryCategoryDataAnalysisChart",
                    name: "inquiryCategoryDataAnalysisChart",
                    component: inquiryCategoryDataAnalysisChart,
                    meta: {
                        title: "品类数据分析",
                        activeMenu:'/dataAnalysis/inquiryAnalysis/inquiryCategoryDataAnalysisTable'
                    },
                },
                {
                    path: "inquiryPlatformDataAnalysisChart",
                    name: "inquiryPlatformDataAnalysisChart",
                    component: inquiryplatformDataAnalysisChart,
                    meta: {
                        title: "平台数据分析",
                        activeMenu:'/dataAnalysis/inquiryAnalysis/inquiryPlatformDataAnalysisTable'
                    },
                },  
                {
                    path: "inquiryCountryDataAnalysisChart",
                    name: "inquiryCountryDataAnalysisChart",
                    component: inquiryCountryDataAnalysisChart,
                    meta: {
                        title: "国家数据分析",
                        activeMenu:'/dataAnalysis/inquiryAnalysis/inquiryCountryDataAnalysisTable'
                    },
                },
                {
                    path: 'inquiryCategoryDataAnalysisTable',
                    name: 'inquiryCategoryDataAnalysisTable',
                    component: inquiryCategoryDataAnalysisTable,
                    meta: {
                        title: '品类数据分析图表'
                    }
                },
                {
                    path: 'inquiryPlatformDataAnalysisTable',
                    name: 'inquiryPlatformDataAnalysisTable',
                    component: inquiryPlatformDataAnalysisTable,
                    meta: {
                        title: '平台数据分析图表'
                    }
                },
                {
                    path: 'inquiryCountryDataAnalysisTable',
                    name: 'inquiryCountryDataAnalysisTable',
                    component: inquiryCountryDataAnalysisTable,
                    meta: {
                        title: '国家数据分析图表'
                    }
                }
            ]
        },
        {
            path: "configView",
            name: "configView",
            component: configView,
            meta: {
                title: "数据可视配置"
            }
        }
    ]
}