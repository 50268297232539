import Set from "@/router/common/set";
import Central from "@/router/common/central";
import DataAnalysis from "@/router/common/dataAnalysis";
import CommodityManagement from "@/router/common/commodityManagement";
import FranchiseeManagement from "@/router/common/franchiseeManagement";
import DmsManage from "@/router/common/dmsManage";
import Recommend from "@/router/common/recommend";
import LabelManage from "@/router/common/labelManage";
import InquiryOrderManage from "@/router/common/InquiryOrderManage";
import FdInquiryOrderManage from "@/router/common/FdInquiryOrderManage";
import NewProduction from "@/router/common/NewProduction";
import ShipmentsManagementCenter from "@/router/common/shipmentsManagementCenter";
import PackAndShipManage from "@/router/common/packAndShipManage";
import InteriorProblem from "@/router/common/interiorProblem";
import Promotion from "@/router/common/promotion";
import Payment from "@/router/common/payment";
import UserCenter from "@/router/common/userCenter";
import Affiliate from "@/router/common/affiliate";
import Financial from "@/router/common/financial";
import ContentCenter from "@/router/common/contentCenter";
import Custom from "@/router/common/custom";
import WebsiteManage from "@/router/common/websiteManage";
import TaskManageWrap from "@/router/common/taskManageWrap";
import KpiManage from "@/router/common/kpiManage";
import DrawingSystem from "@/router/common/drawingSystem";
import EmailManage from "@/router/common/emailManage";
import { constant } from "@/router/common/constantRoutes";
import HrManagement from "@/router/common/hrManagement";
import treeLikeStructure from "@/router/common/tree";
import liyiResume from "@/router/common/resume";
import purchaseManagement from "@/router/common/purchaseManagement";
import promotionCenter from "@/router/common/promotionCenter";
import DevelopmentTaskList from "@/router/common/developmentTaskList";
import liyiHome from "@/router/common/liyiHome";
export const asyncRoutes = [
	Central,
	Set,
	DataAnalysis,
	CommodityManagement,
	FranchiseeManagement,
	DmsManage,
	Recommend,
	LabelManage,
	InquiryOrderManage,
	FdInquiryOrderManage,
	NewProduction,
	ShipmentsManagementCenter,
	PackAndShipManage,
	InteriorProblem,
	Promotion,
	Payment,
	UserCenter,
	Affiliate,
	Financial,
	ContentCenter,
	Custom,
	WebsiteManage,
	TaskManageWrap,
	KpiManage,
	DrawingSystem,
	EmailManage,
	HrManagement,
	treeLikeStructure,
	purchaseManagement,
	promotionCenter,
	liyiResume,
    DevelopmentTaskList,
	liyiHome
]

export const constantRoutes = constant;
