import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getLiyiAdmin() {
	return Cookies.get('liyiadmin')
}
export function removeLiyiAdmin() {
	return Cookies.remove('liyiadmin')
}
export function setLiyiToken(token) {
	return Cookies.set('liyiadmin',token)
}
export function getLiyiUserName() {
	return Cookies.get('liyiUserName')
}
