//liyi官网的假权限
export const userAuthList = [
	{
		id: 9999,
		authorityName: "liyiSetting",
		authorityUrl: null,
		parentId: 0,
		urlTitle: "官网设置",
		iconPath: null,
		isMenu: 1,
		authType: 0,
		childList: [
			{
				id: 10000,
				authorityName: "jobInformation",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "职位信息",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10001,
				authorityName: "commonProblem",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "常见问题",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10002,
				authorityName: "liyiResume",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "简历",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10003,
				authorityName: "annualMeeting",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "公司年会",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10004,
				authorityName: "companyEnvironment",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "公司环境",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10005,
				authorityName: "festivalBenefit",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "节日福利",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			{
				id: 10006,
				authorityName: "newsCenter",
				authorityUrl: null,
				parentId: 9999,
				urlTitle: "新闻中心",
				iconPath: null,
				isMenu: 1,
				authType: 0,
				childList: [],
			},
			//   {
			// 	"id": 10007,
			// 	"authorityName": "userCenter",
			// 	"authorityUrl": null,
			// 	"parentId": 9999,
			// 	"urlTitle": "后台管理",
			// 	"iconPath": null,
			// 	"isMenu": 1,
			// 	"authType": 0,
			// 	"childList": []
			//   },
		],
	},
];
// 属于liyi官网的路由
export const liyiHomePath = [
	"/liyiRegister",
	"/liyiSetting",
	"/liyiSetting/jobInformation",
	"/liyiSetting/commonProblem",
	// '/liyiSetting/userCenter',
	"/liyiSetting/newsCenter",
	"/liyiSetting/festivalBenefit",
	"/liyiSetting/companyEnvironment",
	"/liyiSetting/annualMeeting",
	"/liyiSetting/liyiResume",
];
